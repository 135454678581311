<template>
  <div class="is-fluid p-3 is-flex-direction-column is-align-items-center">
    <b-modal :on-cancel="onCancel" :active="codeSuccess && !appData.prolongUrl">
      <div class="is-fluid p-3">
        <div
          class="box"
        >
          <div class="columns is-mobile is-multiline">
            <div class="column is-12">
              <p class="is-size-6 mb-2">Для подтверждения загрузки ваших персональных данных пожалуйста пройдите верификацию. Мы отправили на вашу электронную почту специальной код.</p>
              <b-field label="Код подтверждения" :message="error">
                <b-input v-model="code" />
              </b-field>
              <b-button expanded type="is-primary" :loading="loading" :disabled="loading || !code" @click="getLink">Подтвердить</b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <div class="w-full is-flex is-justify-content-center">
      <Logo class="mb-5" />
    </div>
    <div class="w-full mb-4" v-if="policies.length < 1">
      Загруза...
    </div>
    <b-tabs size="is-small" v-model="tab" v-else>
      <b-tab-item
        v-for="(data, i) in policies"
        :key="data.number"
        :label="`${data.serial} ${data.number}`"
      >
        <p class="is-size-7 mb-2">
          {{ `Действующее ОСАГО от ${data.companyName || 'СК'}` }}
        </p>
        <div class="box" :style="{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: `url('/policyBack.svg')`, height: 'fit-content'}">
          <div class="columns is-mobile is-multiline">
            <div class="column is-12">
              <h3 class="has-text-centered has-text-weight-bold w-full">Полис автострахования</h3>
            </div>
            <div class="column is-6">
              <text-field :bold="true" label="Серия полиса" :value="data.serial" />
            </div>
            <div class="column is-6">
              <text-field :bold="true" :right="true" label="Номер полиса" :value="data.number" />
            </div>
            <div class="column is-12 is-flex is-justify-content-center">
              <qrcode-vue :value="qrValue" :size="100" level="H" />
            </div>
            <div class="column is-12">
              <text-field label="Водитель" :value="data.fullName" />
            </div>
            <div class="column is-12">
              <text-field label="Регион" :value="data.region" />
            </div>
            <div class="column is-12">
              <text-field label="Гос.номер" :value="data.licensePlate" />
            </div>
            <div class="column is-12">
              <text-field label="ТС" :value="`${data.brand} ${data.model}`" />
            </div>
            <div class="column is-12">
              <text-field label="Дата окончания полиса" :value="format(data.ended)" />
            </div>
            <div class="column is-12">
              <b-button v-if="!appData.prolongUrl && error !== 'nc'" expanded type="is-primary" class="has-text-weight-bold" @click="onProlong">Продлить</b-button>
              <a class="button has-text-weight-bold is-primary is-fullwidth" v-if="!appData.prolongUrl && error === 'nc'" :href="getNewProlongLink()">Оформить новый</a>
              <a class="button has-text-weight-bold is-primary is-fullwidth" v-if="appData.prolongUrl" :href="appData.prolongUrl">Перейти к продлению</a>
            </div>
            <div class="column is-12">
              <b-button expanded type="is-light" class="has-text-weight-bold" @click="onDelete(i)">Удалить</b-button>
            </div>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item
        label="Добавить"
      >
        <search mode="policy" :internalEmail="appData.email" @save="onSave" />
      </b-tab-item>
    </b-tabs>
    <documents />
    <div class="box">
      <h3 class="has-text-centered has-text-weight-bold w-full mb-2">Подписаться на уведомления о штрафах</h3>
      <b-button expanded type="is-primary" class="has-text-weight-bold" :disabled="appData.isSub" @click="onSubscribe">Подписаться</b-button>
    </div>
    <p class="is-size-7 mt-2">В соответствии с поправками к Правилам дорожного движения можно хранить электронный полис ОСАГО на вашем устройстве. Сотрудник ГИБДД должен проверить документ и сопоставить информацию из него с базой.</p>
    <p class="is-size-7 mt-4 has-text-centered">©2021, Blockchain LLC, ООО «Блокчейн», 390006,
Рязань, пл. 26 Бакинских комиссаров, 6/21, пом. H1</p>
  </div>
</template>

<script>
import TextField from './TextField.vue';
import QrcodeVue from 'qrcode.vue'
import dayjs from 'dayjs'
import Logo from '@/assets/img/logo.svg'
import Documents from './Documents.vue';
import md5 from 'md5';
import Search, { gs } from '../Main/Search.vue';

export default {
  components: { TextField, QrcodeVue, Logo, Documents, Search },
  data() {
    return {
      appData: {},

      tab: 0,

      policies: [],

      codeSuccess: false,
      code: '',

      error: '',

      isSearch: false,

      loading: false,
    }
  },

  computed: {
    qrValue() {
      return encodeURI(
        `https://osago.red/policy?codeNumber=${this.appData.licensePlate}`
      )
    },
  },

  async mounted() {
   this.appData = this.$route.query
   this.getPolicies()
  },
  methods: {
    onCancel() {
      this.codeSuccess = false
    },
    getNewProlongLink() {
      return `https://osago.red${window.location.search}&utm_source=iframe&is_app=true&header=false&chan=mobapp#affid=in`
    },
    onSave(data) {
      this.policies.unshift(data)

      this.tab = 0
    },
    onDelete(i) {
      this.policies.splice(i, 1)

      if (this.policies.length < 1) {
        return this.$router.push({ name: 'main' })
      }

      this.$router.push({ ...this.$route, query: { ...this.$route.query, policies: this.policies.map(p => p.licensePlate) } })

      this.tab = this.policies.length - 1
    },
    async getPolicies() {
      try {
        if (this.appData.policies) {
          const policies = this.appData.policies

          if (Array.isArray(policies)) {
            for (const plate of policies) {
              const key = md5(`n78Qt${gs(1)}${gs(
                  undefined
              )()}/${new Date().toISOString().substr(0, 13)}`)

              const { data } = await this.$api.getPolicyInfo({ licensePlate: plate, email: this.appData.email, key })

              this.policies.push(data.data)
            }
          } else {
            const key = md5(`n78Qt${gs(1)}${gs(
                undefined
            )()}/${new Date().toISOString().substr(0, 13)}`)

            const { data } = await this.$api.getPolicyInfo({ licensePlate: policies, email: this.appData.email, key })

            this.policies.push(data.data)
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    async onProlong() {
      try {
        await this.$api.getProlongCode({ email: this.appData.email })

        this.codeSuccess = true
      } catch (error) {
        this.error = 'nc'
        console.error(error)
      }
    },
    async getLink() {
      this.loading = true

      this.error = ''
      try {
        const { data } = await this.$api.getProlongLink({ email: this.appData.email, code: this.code })

        this.$router.push({ ...this.$route, query: { ...this.$route.query, ...data, ...(data.documents && { documents: JSON.stringify(data.documents) }) } })
          .catch(() => {})

        this.appData = { ...this.appData, ...data }
      } catch (error) {
        console.error(error)
        this.error = 'Неверный код'
      } finally {
        this.loading = false
      }
    },
    format(date) {
      return dayjs(date).format('DD.MM.YY')
    },
    onSubscribe() {
      this.appData = { ...this.appData, isSub: true }
      this.$router.replace({ ...this.$route, query: { ...this.$route.query, ...this.appData } }).catch(() => {})
    }
  }
};
</script>

<style>
.tab-content {
  padding: 0 !important;
}
</style>
