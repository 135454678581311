<template>
  <div class="box">
    <b-collapse
      class="card"
      animation="slide"
      :open="isOpen === 1"
      @open="isOpen = 1"
    >
      <template #trigger="props">
        <div class="card-header" role="button">
          <p class="card-header-title">СТС</p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
          </a>
        </div>
      </template>
      <div class="card-content">
        <b-field label="Серия">
            <b-input v-model="documents.sts.serial" placeholder="Введите серию"></b-input>
          </b-field>
          <b-field label="Номер">
            <b-input v-model="documents.sts.number" placeholder="Введите номер"></b-input>
          </b-field>
          <b-field label="Дата выдачи">
              <b-datepicker
                v-model="documents.sts.date"
                show-week-number
                placeholder="Выбрать дату..."
                icon="calendar-today"
                :icon-right="documents.sts.date ? 'close-circle' : ''"
                icon-right-clickable
                @icon-right-click="documents.sts.date = undefined"
                trap-focus
            >
            </b-datepicker>
          </b-field>
      </div>
    </b-collapse>
    <b-collapse
      class="card"
      animation="slide"
      :open="isOpen === 2"
      @open="isOpen = 2"
    >
      <template #trigger="props">
        <div class="card-header" role="button">
          <p class="card-header-title">Водительское удостоверение</p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
          </a>
        </div>
      </template>
      <div class="card-content">
        <div class="content">
          <b-field label="Серия">
            <b-input v-model="documents.vu.serial" placeholder="Введите серию"></b-input>
          </b-field>
          <b-field label="Номер">
            <b-input v-model="documents.vu.number" placeholder="Введите номер"></b-input>
          </b-field>
          <b-field label="Дата начала стажа">
              <b-datepicker
                v-model="documents.vu.date"
                show-week-number
                placeholder="Выбрать дату..."
                icon="calendar-today"
                :icon-right="documents.vu.date ? 'close-circle' : ''"
                icon-right-clickable
                @icon-right-click="documents.vu.date = undefined"
                trap-focus
            >
            </b-datepicker>
          </b-field>
        </div>
      </div>
    </b-collapse>
    <b-collapse
      class="card"
      animation="slide"
      :open="isOpen === 3"
      @open="isOpen = 3"
    >
      <template #trigger="props">
        <div class="card-header" role="button">
          <p class="card-header-title">Паспорт</p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
          </a>
        </div>
      </template>
      <div class="card-content">
        <b-field label="Серия">
            <b-input v-model="documents.passport.serial" placeholder="Введите серию"></b-input>
          </b-field>
          <b-field label="Номер">
            <b-input v-model="documents.passport.number" placeholder="Введите номер"></b-input>
          </b-field>
          <b-field label="Дата выдачи">
              <b-datepicker
                v-model="documents.passport.date"
                show-week-number
                placeholder="Выбрать дату..."
                icon="calendar-today"
                :icon-right="documents.passport.date ? 'close-circle' : ''"
                icon-right-clickable
                @icon-right-click="documents.passport.date = undefined"
                trap-focus
            >
            </b-datepicker>
          </b-field>
          <b-field label="Кем выдан">
            <b-input v-model="documents.passport.issuedBy" placeholder="Кем выдан"></b-input>
          </b-field>
      </div>
    </b-collapse>
    <b-button expanded type="is-primary" class="has-text-weight-bold mt-2" @click="onSave">Сохранить</b-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: 0,
      documents: {
        sts: {
          serial: "",
          number: "",
          date: new Date(),
        },
        vu: {
          serial: "",
          number: "",
          date: new Date(),
        },
        passport: {
          serial: "",
          number: "",
          date: new Date(),
          issuedBy: "",
        },
      },
    };
  },
  mounted() {
      try {
          const data = JSON.parse(this.$route.query.documents)

          data.sts.date = new Date(data.sts.date)
          data.vu.date = new Date(data.vu.date)
          data.passport.date = new Date(data.passport.date)
          this.documents = data
      } catch (error) {
        // - err
      }
  },
  methods: {
    onSave() {
        this.$router.push({ ...this.$route, query: { ...this.$route.query, documents: JSON.stringify(this.documents) } }).catch(() => {})
    }
  },
  watch: {
    '$route.query.documents': {
      handler(val) {
        if (val) {
              try {
              const data = JSON.parse(this.$route.query.documents)

              data.sts.date = new Date(data.sts.date)
              data.vu.date = new Date(data.vu.date)
              data.passport.date = new Date(data.passport.date)
              this.documents = data
          } catch (error) {
            // - err
          }
        }
      }
    }
  }
};
</script>

<style>
</style>